@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300&family=Montserrat:wght@100;300;500&display=swap');

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #EDEDEE;
}

a {
    color: inherit;
}

.list-group-flush {
    color: #495057;
}

.active {
    background-color: #e9ecef !important;
}

main {
    background-color: #EDEDEE;
}

.navbar-brand {
    margin-left: 15px;
    color: #2196f3 !important;
}

.sidebar-fixed {
    left: 0;
    top: 0;
    height: 100vh;
    width: 270px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    z-index: 1050;
    background-color: #fff;
    padding: 1.5rem;
    padding-top: 0;
}


.flexible-navbar {
    transition: padding-left 0.5s;
    padding-left: 270px;
    background: #fff;
}

.sidebar-fixed .logo-wrapper img {
    width: 100%;
    padding: 1.5rem;
}

.list-group-item {
    display: block !important;
    transition: background-color 0.3s;
    border: none;
}

.list-group-item:hover {
    color: #49505B;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item:hover {
    color: #49505B;
    text-decoration: none;
    background-color: #f8f9fa
}


.list-group .active {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

.client-name {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2%;
}

.hr-client {
    padding: 0;
    margin: 0 auto 3% auto;
}

.client-card-title {
    background-color: rgba(198, 209, 224, 0.58) !important;
    margin-bottom: 0 !important;
    color: #3a7bc2;
}

.card-title {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

}

.card-subtitle {
    font-size: 1.25rem;
    font-weight: 600;
}

.client-data-title {
    padding: 4%;
}

.client-data {
    display: flex;
    /*justify-content: space-between;*/
    padding-left: 3%;
}

.client-name {
    line-break: anywhere;
}

.full {
    height: 70vh;
}

.bad-gateway-row {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
}


.card .view.gradient-card-header {
    padding: 1rem 1rem;
    text-align: center;
}

.card h3, .card.card-cascade h4 {
    margin-bottom: 0;
}

.cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px;
}

.cascading-admin-card .admin-up .fa {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
    padding: 1.7rem;
    font-size: 2rem;
    color: #fff;
    text-align: left;
    margin-right: 1rem;
    border-radius: 3px;
}

.cascading-admin-card .admin-up .data {
    float: right;
    margin-top: 2rem;
    text-align: right;
}

.admin-up .data p {
    color: #999999;
    font-size: 12px;
}

.classic-admin-card .card-body {
    color: #fff;
    margin-bottom: 0;
    padding: 0.9rem;
}

.classic-admin-card .card-body p {
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 0;
}

.classic-admin-card .card-body h4 {
    margin-top: 10px;
}

.form-inline {
    flex-flow: unset
}

.breadcrumb {
    margin: 0;
    align-items: center;
}

.activeClass .list-group-item {
    z-index: 5;
    color: #fff;
    border-color: #007bff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px !important;
    background-color: #007bff !important;
}

.client-info {
    height: 75% !important;
}


.page-footer {
    margin-left: 270px;
}

.client-card {
    margin-right: 1px;
}

.comment-date {
    font-size: 10px;
    padding-right: 10%;
    color: #999999;
}


.cases-pie-card {
    align-content: center;
    display: flex;
    justify-content: center;
}

.comment-date-left {
    font-size: 10px;
    padding-left: 10%;
    color: #999999;
}

@media (min-width: 992px) {
    .main-container {
        padding-left: 310px !important;
    }

    .user-card {
        display: flex;
        justify-content: space-evenly;
    }
}

@media (min-width: 1200px) {
    .case-title {
        padding-bottom: 0 !important;
    }

    .info-card {
        height: 60% !important;
    }

    .hr-postings {
        margin: 0 !important;
    }
}

.clossed-status {
    font-size: 12px !important;
}

.text-area-comment {
    height: 100px;
    width: 100%;
    border: 1px solid #c9cdd2;
    padding: 2%;
    font-size: 15px;
    font-weight: 300;
    color: #2196f3;
}

@media (max-width: 1199.98px) {
    .flexible-content {
        padding-left: 0;
    }


    .flexible-navbar {
        padding-left: 10px;
    }

    .page-footer {
        margin-left: 0;
    }

    .card.cascading-admin-card {
        margin-top: 2.5rem;
    }
}

.header-buttons {
    display: contents;
}

.log-out-button {
    display: flow-root;
}

.postings-row {
    display: flex;
}

.offer-name {
    font-size: 15px;
    display: flex;
    text-align: center !important;
    font-weight: 600;
}

.offer-badge {
    padding: 1% !important;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

@media (max-width: 900px) {
    .header-buttons {
        display: flex;
        justify-content: right;
        min-width: 200px;
    }


    .sidebar-list-item {
        font-size: 15px !important;
    }

    .sidebar-list-item-static {
        font-size: 15px !important;
    }

    .product-button {
        padding: 0 !important;
    }

    .product-card {
        margin-bottom: 3%;

    }

    .add-product {
        padding: 2px !important;
    }

    .card-title {
        font-size: 18px !important;
        margin-top: 3% !important;
    }

    .posting-btn {
        display: flex !important;
        justify-content: center !important;
    }

    .header-button {
        padding-left: 0 !important;
        margin: 1%;
        display: inline-flex;
        align-items: baseline;
        border-radius: 5px;

    }

    .toggle-button {
        display: flex;
        align-items: baseline;
        border-radius: 5px;
        background-color: white;
        color: #2196f3;
        margin-right: 2%;
    }

    .log-out-button {
        border-radius: 5px !important;
        background-color: white;
        color: #2196f3;
        border-color: #2196f3;
        padding-left: 2%;
    }

    .toggle-button:hover {
        background-color: #2196f3;
        color: white
    }

    .log-out-button:hover {
        background-color: #2196f3;
        color: white;
    }

    .nav-container {
        /*padding: 2%;*/
        background-color: white !important;
    }

    .header-button {
        display: contents;
        padding-right: 2% !important;
    }

}

.nav-tabs {
    padding-left: 10%;
}

.clients-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2em;
}

.clossed-status {
    font-size: 12px !important;
    padding-top: 5%;
}

.breadcrump-items {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.toggle-button {
    display: flex;
    align-items: baseline;
    border-radius: 5px;
    background-color: white;
    color: #2196f3;
    border-color: #2196f3;
}

.sidebar-elements {
    border-bottom: none !important;
}

.img-side-div {
    height: 20%;
}

.custom-control-label {
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    color: #2196f3 !important;
}

.display-all-cases {
    margin-top: 2%;
    display: flex;
    justify-content: end;
}

.log-out-button {
    border-radius: 5px !important;
    background-color: white;
    color: #2196f3;
    border-color: #2196f3;
}

.toggle-button:hover {
    background-color: #2196f3;
    color: white
}

.log-out-button:hover {
    background-color: #2196f3;
    color: white;
}

@media (max-width: 576px) {
    .nav-container {
        padding: 2%;
        background-color: white !important;
    }

    .sidebar-list-item {
        font-size: 15px !important;
    }

    .sidebar-list-item-static {
        font-size: 15px !important;
    }

    .clients-container {
        display: flex;
        justify-content: center;
    }

    .tab-title {
        font-size: 13px;
        margin-bottom: 5px;
        padding-right: 10px !important;
    }

    .your-msn {
        font-size: 12px !important;
    }

    .flexible-content {
        width: fit-content;
    }

    .sidebar-padding {
        height: 100%;
    }

    .postings-list {
        font-size: 12px !important;
    }

    .comments-8pt {
        min-width: 0 !important;
    }

    .card-header {
        align-items: center;
        min-height: 80px;
    }


    .posting-cards {
        min-width: 240px !important;
    }

    .postings-col {
        padding-left: 20px !important;
    }

    .header-button {
        padding-right: 5px !important;
        display: flex !important;
        justify-content: flex-end !important;

    }

    .p-5 {
        padding: 0.5rem !important;
    }

    .nav-container {
        display: inline-flex !important;

    }

    .postings-col {
        margin: auto;
    }


    .card.cascading-admin-card {
        margin-top: 2.5rem;
    }

    .sidebar-fixed {
        display: inherit;
        justify-content: center !important;
    }

    .client-card {
        margin-bottom: 7% !important;
    }

    .client-details {
        line-break: anywhere !important;
    }

    .line-clients {
        padding: 0 !important;
    }

    .logo-wrapper {
        margin: 15%;
    }

    .user-titles {
        padding-left: 5% !important;
    }

    .title-and-button {
        float-displace: auto;
    }

    .add-product {
        padding: inherit;
    }

    .react-confirm-alert-overlay {
        z-index: 2000;
    }

    .product-card {
        margin-bottom: 15px;
    }

    .nav-breadcrump {
        display: contents;
        background-color: #999999;
    }

    .breadcrump-items {
        font-weight: bold;
        color: #2196f3;
    }

    #breadcrumb {
        display: flex;
        align-items: baseline !important;
    }

    .sidebar-padding {
        width: 36%;
    }

    .logo-wrapper {
        margin: 0;
    }

}

.clients-container {
    margin-top: 20px;
}

.header-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 165px !important;
}

.breadcrump-item-link {
    font-size: 12px !important;
}

.breadcrump-items {
    align-items: baseline !important;
}

.toggle-button {
    font-size: 15px !important;
}

.log-out-button {
    font-size: 15px !important;
}

.breadcrump-item {
    display: flex;
    justify-content: center;
    text-align: center;
}

.sidebar-padding {
    padding: 0 !important;
}

.submenu-link {
    font-size: 18px;
    font-weight: 400;
    color: #49505B;
    /*padding: 5px 0 0 20px;*/
    list-style-type: none;

}

.spinner-border {
    margin-left: 55%;
    margin-top: 20%;
}

.header-buttons {
    display: flex;
}

/* css submenu*/

.sidebar-links {
    line-height: 2.5;
}

.sidebar-list-item {
    color: #007bff;
    background-color: white;
    padding: 0 !important;
}

.sidebar-list-item-static {
    color: #007bff;
    background-color: white;
    padding: 0 !important;
}

.btn-blue {
    background-color: white !important;
    border-color: #2196f3;
    color: #2196f3;
}

.sign-in-form {
    display: flex;
    justify-content: center;
}

.sign-in-input {
    width: 400px;
}

.navbar-collapse {
    height: 40px !important;
    background-color: #007bff;
    padding-right: 5px;
    opacity: 0.9;
}

.sidebar-list-item:hover {
    color: white;
    background-color: #007bff;
    cursor: pointer;
    font-weight: 800;
}

.sidebar-list-item-static:hover {
    color: white;
    background-color: #007bff;
    cursor: pointer;
    font-weight: 800;
}

.submenu-list-element {
    list-style: none;
}

.submenu-list-element:hover {
    color: #007bff;
    background-color: #f5efef;
    font-weight: 600;
    cursor: pointer;
}

.sidebar-list-item {
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
}

.sidebar-list-item-static {
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
}

.group-list {
    padding-left: 0 !important;
}

.submenu-icon {
    margin-right: 6%;
}

.list-group-item {
    padding: 0 !important;
    display: flex !important;
}

/*css footer*/
.footer-color {
    background-color: #007bff !important;
}

.footer-main {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
}

.card-badge {
    background-color: #2196f3 !important;
}

.logo-sign-in {
    display: flex;
    justify-content: center;
    width: 300px;
    padding: 70px 0;
}

.text-center {
    padding-top: 4%;
    text-transform: uppercase;


}

.sign-in-container {
    display: flex;
    justify-content: center;
}

.card-sign-in {
    width: max-content;
}

.sign-in-input {
    padding-left: 3% !important;
}

.sign-in-logo {
    padding: 0 !important;
}

.identification {
    color: #2196f3 !important;
    font-size: 22px;
}

.nav-items {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding-right: 10px;
}

.log-out-btn {
    border-radius: 5px;
}

.tab-title {
    display: flex;
    justify-content: center;
    padding-right: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.img-fluid {
    display: flex;
    justify-content: center !important;
    padding: 0 20%;

}

.cases-pie {
    justify-content: center;
    align-items: center;
}

.cards-container {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2em;
}

.open-close-btns {
    display: flex;
    justify-content: center;
    /*margin: 2%;*/
}

.tabs-main {
    padding-left: 5%;
}

.close-case-cotnainer {
    margin: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}

.view {
    display: flex;
    justify-content: center;
}

.case-title {
    display: flex;
    justify-content: flex-start;
    padding-left: 10%;
    padding-bottom: 3%;
    padding-top: 1%;
}

.subject-issue {
    font-weight: 600 !important;
    color: #007bff;
    justify-content: flex-start;
}

.assigned-to {
    display: flex;
    align-items: center;
}

.card-status {
    display: flex;
    justify-content: flex-end;
}

.open-status {
    color: #47e070;
    text-transform: uppercase;
}

.clossed-status {
    color: red;
    text-transform: uppercase;
}

.created-on {
    font-size: 12px;
}

.posting-card-body {
    padding-bottom: 0 !important;
}

.status-created-on {
    margin-top: 3%;

}

.created-on-date {
    font-size: 15px;
    line-break: unset;
    padding-right: 0;
}

.modal-content {
    min-height: 350px;
    min-width: 440px;
}

.products-list-title {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: #2196f3;
    margin-bottom: 3rem;
}

.product-card > div > .Ripple-parent {
    display: flex !important;
    justify-content: center;
    padding: 3%;
}

.img-product {
    width: 100%;
}

.list-group-item-category {
    padding: 1em !important;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 1.5; /* fallback */
}

.product-card {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5%;
    border-radius: 5px;
    width: 250px;
    /*height: 350px !important;*/
}

.product-badge {
    background-color: rgba(198, 209, 224, 0.58) !important;
    padding: 5%;
    color: #2196f3 !important;
    font-size: 15px;
}

.product-name {
    display: flex;
    justify-content: center;
    margin-top: 15%;
    font-size: 20px;
    color: #3a7bc2;
}

.product-button {
    margin: 10px 60px;
    background-color: #2196f3;
    color: white;
    font-size: 15px;
    border-color: #2196f3;
    border-radius: 5px;
    padding: 1%;
}

.product-button:hover {
    background-color: white;
    color: #2196f3;
    font-weight: 300;
}

.modal-title {
    color: #2196f3;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.btn-outline-blue {
    border-color: #a7c9dc !important;
    color: #007bff !important;
}

.button-container {
    display: flex;
    justify-content: end;
}

.main-container {
    padding-top: 1% !important;
    padding-left: 2%;
    padding-right: 2%;
}

.add-product {

    background-color: #2196f3 !important;
    color: white !important;
    border-radius: 5px;
    font-weight: bold;
}

.add-product:hover {
    background-color: #2196f3;
}

.nav-breadcrump {
    padding-left: 270px;
}

.breadcrump-item-link {
    color: #2196f3 !important;
}

.sign-out-btn {
    padding: 0 !important;
    border-radius: 5px;
}

.card-header {
    display: flex !important;
    justify-content: space-between !important;
    border-radius: 4px;
    padding: 5px;
    background-color: transparent;
    border-bottom: none;
}

.panel-tittle {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 22px;
    color: #3a7bc2;
}

.tab-title:hover {
    color: #007bff !important;
}


.detail-list {
    margin-top: 3%;
    list-style: none;
}

.panel-title {
    padding: 0 !important;
}

.user-title {
    color: #2196f3;
    font-size: 18px !important;
}

.user-name {
    color: #49505B;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-left: 5%;
    line-break: anywhere;
}

.footer-statusi {
    display: flex;
    justify-content: end;
    color: #1acb1a;
    padding: 0 2% 1%;
}

.client-card {
    margin-bottom: 2%;
    /*min-width: fit-content !important;*/
}

.list-details {
    margin: 1% 3%;
}

.modal-btns {
    padding: 10px !important;
    border-radius: 5px;
    text-transform: capitalize !important;
}

.md-form {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.modal-input-text {
    font-size: 25px;
    font-weight: bold;
    display: flex;
    text-align: center;
}

.modal-input-text:hover {
    cursor: pointer;
}

.modal-input-client {
    padding-left: 4% !important;
}

.card-badge {
    background-color: #bec7d4;
}

.md-input {
    display: flex;
    justify-content: center;
}

.modal-hr {
    margin-bottom: 0 !important;
}

.client-modal {
    margin: 0 !important;
}

.update-client-span {
    display: flex;
    justify-content: flex-end;

}

/*.container-fluid {*/
/*    padding-right: 0 !important;*/
/*}*/

.client-button {
    border-radius: 5px;
    background-color: #2196f3;
    color: white;
    border: none;
    font-size: 13px;
}

.client-button:hover {
    background-color: white;
    color: #2196f3;
    border-color: #007bff;
}

.modal-input-client {
    font-weight: bold;
}

.client-modal-footer {
    background-color: white;
}

.modal-info {
    border-color: #49505B;
}

.modal-label {
    margin: auto auto auto 10px;
}

.offer-card {
    width: 300px;
    /*height: 220px;*/
    padding-left: 0 !important;
    padding-right: 0 !important;

}

.update-client-span {
    padding: 2%;
}

.offer-button {
    margin: 1% !important;
}

.offer-type {
    display: flex;
    align-items: center;
}

.offer-title {
    display: flex;
    justify-content: flex-start;
    padding: 0 !important;
}

.offer-name {
    color: #2196f3;
    display: flex;
    justify-content: center !important;
    padding-top: 4% !important;
}

.detail-offer {
    display: block;
    padding: 0 5%;
}

.offer-price {
    font-size: 23px;
    font-weight: bold;
    color: white;
}

.name-offer {
    font-size: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 4%;
    color: #3a7bc2;
}

.offer-details {
    margin-bottom: 2%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    align-items: center;
    font-weight: 400;
    color: #152a38;
}

.offer-container {
    min-height: 210px
}

.footer-offer {
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
    color: #1acb1a;
}

.users-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1em;
    margin-bottom: 2em;
}

.user-title {
    display: flex;
    justify-content: space-between;
    background-color: rgba(198, 209, 224, 0.58);

}

.offer-badge-price {
    background-color: #007bff !important;
}

.user-details {
    padding: 2%;
    overflow-wrap: unset;
    font-size: 15px;

}

.users-details {
    padding-left: 2%;
    color: #2196f3;
}

hr {
    padding: 2%;
}

.email-user {
    overflow-wrap: anywhere;
    color: #49505B;
    font-size: 12px !important;
    margin: auto;
    /*color: #3a7bc2;*/
}

.nav-breadcrump {
    min-height: 40px;
}


.postings-row {
    flex-wrap: inherit;
    padding: 0;
    margin: 0;
}

.img-div {
    display: flex;
    justify-content: center;
}

.img-in-posting {
    max-width: 55%;
}

.assigned-to-title {
    color: #2196f3;
    font-weight: 600;
}

.info-card {
    padding: 2% !important;
    height: 70%;
}

.posting-cards {
    min-height: 350px;
    min-width: 300px;
    margin: 8px;
}

.hr-statusi {
    margin-bottom: 0 !important;
}

.close-case-btn {
    padding: 2px !important;

}

.case-status {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-case-btn {
    display: flex;
    justify-content: center;
    background-color: #a8a2a2 !important;
}

.close-case-btn:hover {
    color: white;
    background-color: #2196f3 !important;
}

.close-case-title {
    font-size: 25px;
    text-align: center;
    color: #2196f3;
}

.postings-hr {
    margin-top: 1%;
    margin-bottom: 1%;
}

.close-case-md-body {
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.card-status-title {
    display: flex;
    justify-content: center;

}

.clients-spinner {
    margin-left: 40% !important;
}

.case-card {
    margin: 10px;
    padding: 5px !important;
    min-height: 420px;
    width: 300px !important;
}

.user-name {
    text-transform: uppercase;
    margin-top: 1rem;
}

.footer-b {
    padding-top: 0 !important;
}

.status-active {
    color: #15c915;
    font-weight: 600 !important;
    font-size: 14px;
}

.btn-sign-in {
    color: white;
    background-color: #2196f3 !important;

}

.btn-sign-in:hover {
    color: #2196f3 !important;
    background-color: white !important;
    font-weight: bolder;
}

.status-inactive {
    color: red;
    font-weight: 600 !important;
}

.case-statuses {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
}

.postings-card {
    height: 820px;
}

.posting-card-title {
    display: flex;
    justify-content: flex-start;
    padding-top: 2%;
    padding-left: 2%;
    font-size: 13px;
    color: #000000;
    font-weight: light;
}

.drag-and-drop-text {
    font-size: 12px;
    text-align: center;
}

.modal-label {
    border: 1px solid #e8dfdf;
}

.drag-and-drop-div {
    margin: auto 5px;
}

.drag-and-drop {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #2196f3;
}

.comments {
    background-color: #f3f4f5;
}

.postings-col {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 3%;
}

.card-img {
    height: 75%;
}

.card-body {
    min-height: auto;
}

.subject-issue {
    font-size: 14px;
}

.card-registered-by {
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    line-break: anywhere;
}


.msg-div {
    background-color: white;
    width: 80%;
    padding: 2%;
    margin: 3% 2%;
    border-radius: 5px;
    border: solid 1px #ccccd5;
    mso-border-shadow: yes;
}

.msg-btns {
    padding: 0;
}

.posting-btn {
    background-color: #ffffff !important;
    color: #2196f3 !important;
    padding: 2% !important;
    width: 80px;
    height: 25px;
    font-size: 10px;
    border-radius: 5px;
    display: flex !important;
    align-items: center;

}

.comments-row {
    padding: 3% 5%;
}

.comments-2pt {
    display: flex;
    justify-content: center;
    background-color: #2196f3;
    min-height: 40px;
    border-radius: 100px;
    color: white;
}

.comments-8pt {
    display: flex;
    justify-content: center;
    background-color: #e5e7ec;
    min-height: 40px;
    border-radius: 100px;
    font-size: 13px;
    color: #2479c4;

}

.comments-div {
    background-color: white;
    width: 95%;
    height: 690px;
    border: solid 1px #ccccd5;
    border-radius: 5px;
    overflow: auto;
}

.comments-card {
    margin-bottom: 0 !important;
}

.drag-drop-div {
    width: 200px;
    height: 80px;
    background-color: #e7e3e3;
    border-radius: 5px;
    border: solid #f1ecec 1px;

}

.user-name-check {
    line-break: anywhere;
    font-size: 10px;
}

.open-cases-line {
    padding: 3%;
}

.deleteCommentBtn {
    padding: 0.2em 0.6em 0.2em 0.6em;
}
